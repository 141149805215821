import { memo, type JSX } from 'react';
import { useSelector } from 'react-redux';
import { zoneInfoSelector } from '../../store/slices/application-selectors';
import { isImmersiveSelector } from '../../store/slices/immersive-selectors';
import { AlternateLinksTags } from '../AlternateLinksTags/AlternateLinksTags';
import { useRoutingContext } from '../Page/RoutingContext';
import { CanonicalLinkTags } from './CanonicalLinkTags';
import type { AllQueryHandlerResult } from './getFormattedMeta';
import { getFormattedMeta } from './getFormattedMeta';
import { MetaTags } from './MetaTags';

type MetaTagsTemplateProps = {
  disableMetaUpdate?: boolean;
  enableAlternateLinksUpdate?: boolean;
  data?: AllQueryHandlerResult;
};

function MetaTagsTemplateComponent({
  disableMetaUpdate = false,
  enableAlternateLinksUpdate = false,
  data,
}: MetaTagsTemplateProps): JSX.Element | null {
  const { offerLocation, appKey } = useSelector(zoneInfoSelector);

  const routingContext = useRoutingContext();
  const isImmersive = useSelector(isImmersiveSelector);

  // We don't need metadata on tv device, don't necessary to render react-helmet here
  if ($_BUILD_RENDERMODE_CSR) {
    return null;
  }

  // If immersive is opened upon page, don't render react-helmet to keep only metadatas of immersive
  if (routingContext === 'page' && isImmersive) {
    return null;
  }

  if (!data) {
    return null;
  }

  // metas
  const meta = getFormattedMeta({
    data,
    offerLocation,
    appKey,
  });

  const currentPage = data.pages?.[0]?.currentPage || data.currentPage;
  const displayTemplate = currentPage?.displayTemplate ?? '';

  return (
    <>
      {!disableMetaUpdate && (
        <CanonicalLinkTags metaCanonical={meta?.canonical} />
      )}
      {!disableMetaUpdate && meta && (
        <MetaTags meta={meta} displayTemplate={displayTemplate} />
      )}
      {enableAlternateLinksUpdate && <AlternateLinksTags data={data} />}
    </>
  );
}

const MetaTagsTemplateTv = () => null;

export const MetaTagsTemplate = $_BUILD_RENDERMODE_CSR
  ? MetaTagsTemplateTv
  : memo(MetaTagsTemplateComponent);
