import type { JSX } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { getAlternateLinks } from '../../helpers/metaTags/metaTags-helper';
import { offerLocationSelector } from '../../store/slices/application-selectors';
import { pathnameSelector } from '../../store/slices/routing-selectors';
import type { AllQueryHandlerResult } from '../MetaTags/getFormattedMeta';
import { useHostnameLink } from '../MetaTags/hooks/useHostnameLink';

type AlternateLinksTagsProps = {
  data: AllQueryHandlerResult;
};

/**
 * Handle alternate link meta tag
 * values of alternate links are determined by currentPage.alternate property from hodor response
 */
export function AlternateLinksTags({
  data,
}: AlternateLinksTagsProps): JSX.Element | null {
  const offerLocation = useSelector(offerLocationSelector);
  const pathname = useSelector(pathnameSelector) || '';
  const hostname = useHostnameLink();

  const currentPage = data.pages?.[0]?.currentPage || data.currentPage;
  const alternateLinks = currentPage?.alternate;

  if (!alternateLinks) {
    return null;
  }

  const links = [
    // add alternate links
    ...getAlternateLinks(alternateLinks, hostname, pathname, offerLocation),
  ];

  return <Helmet link={links} />;
}
