import type { JSX } from 'react';
import LoadableError from '../../../components/Error/LoadableError';
import { MetaTagsTemplate } from '../../../components/MetaTags/MetaTagsTemplate';
import { useQueryTemplate } from '../../../helpers/hooks/useQueryTemplate/useQueryTemplate';
import { FetchRequestTypes } from '../../../services/types';
import type { ErrorData } from '../data/formatter';

export type ErrorContainerProps = {
  url?: string;
  title?: string;
  text?: string;
};

function ErrorContainer({
  url,
  title,
  text,
}: ErrorContainerProps): JSX.Element | null {
  const [{ data }] = useQueryTemplate<ErrorData>(
    url,
    {
      template: FetchRequestTypes.Error,
    },
    {
      enabled: !!url,
    },
  );

  return (
    <>
      <MetaTagsTemplate data={data} />
      <LoadableError title={data?.title || title} text={data?.text || text} />
    </>
  );
}

export default ErrorContainer;
